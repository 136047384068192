"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAppSelector = exports.rootReducer = void 0;

var _redux = require("redux");

var _reduxModal = require("redux-modal");

var _reducer = require("./modules/auth/reducer");

var _reducer2 = require("./modules/appSetup/reducer");

var _issues = require("./modules/_BETA/issues");

var _reducer3 = require("./modules/users/reducer");

var _reducer4 = require("./modules/lookups/reducer");

var _issue = require("./modules/_BETA/issue");

var _reducer5 = require("./modules/insights/reducer");

var _associate = require("./modules/_BETA/associate");

var _unassociate = require("./modules/_BETA/unassociate");

var _snooze = require("./modules/_BETA/snooze");

var _unsnooze = require("./modules/_BETA/unsnooze");

var _reducer6 = require("./modules/action/reducer");

var _actionsUI = require("./modules/_BETA/actionsUI");

var _requestSchedule = require("./modules/_BETA/requestSchedule");

var _selections = require("./modules/_BETA/selections");

var _reducer7 = require("./modules/userSites/reducer");

var _reducer8 = require("./modules/urlHistory/reducer");

var _reducer9 = require("./modules/monitoring/reducer");

var _reactRedux = require("react-redux");

const rootReducer = (0, _redux.combineReducers)({
  auth: _reducer.reducer,
  users: _reducer3.reducer,
  lookups: _reducer4.reducer,
  // BETA Reducers
  appSetup: _reducer2.reducer,
  betaModal: _reduxModal.reducer,
  userSites: _reducer7.reducer,
  betaSelections: _selections.reducer,
  betaIssues: _issues.reducer,
  betaIssue: _issue.reducer,
  insights: _reducer5.reducer,
  betaAction: _reducer6.reducer,
  betaActionsUI: _actionsUI.reducer,
  betaAssociate: _associate.reducer,
  betaUnassociate: _unassociate.reducer,
  betaSnooze: _snooze.reducer,
  betaUnsnooze: _unsnooze.reducer,
  requestSchedule: _requestSchedule.reducer,
  urlHistory: _reducer8.reducer,
  monitoring: _reducer9.reducer
});
/**
 * The entire application state.
 *
 * Useful for typing `mapStateToProps` function parameters.
 */

exports.rootReducer = rootReducer;
const useAppSelector = _reactRedux.useSelector;
exports.useAppSelector = useAppSelector;