"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCurrencySymbol = getCurrencySymbol;

/**
 * Provided an organisation key, return the related currency symbol.
 *
 * @param projectKey The Organisation's key (e.g. GOOG, WOOL, etc.)
 */
function getCurrencySymbol(projectKey) {
  switch (projectKey) {
    case 'GOOGLE':
    case 'ASHBY':
    case 'PRIM': // Optimised

    case 'LZI':
    case 'LCC':
      return '£';

    case 'DISNEY':
      return '€';

    default:
      return '$';
  }
}